// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyDhji_pWtBwBoN9bo0MuEGcT2ZsYpqQTtU",
    authDomain: "farmacia-settimo-miglio.firebaseapp.com",
    projectId: "farmacia-settimo-miglio",
    storageBucket: "farmacia-settimo-miglio.appspot.com",
    messagingSenderId: "145453695031",
    appId: "1:145453695031:web:bf73f2eb025756f81effb2",
    measurementId: "G-VVT355PDNF",
};

// navigation menu
export const navigation = [
    { label: "home", url: "/" },
    { label: "chi siamo", url: "/chi-siamo" },
    { label: "aree", url: "/aree" },
    { label: "servizi", url: "/servizi" },
    { label: "contatti", url: "/contatti" },
];
