import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

// compoent props
interface SeoProps {
    /** page title */
    title: string;
    /** page description */
    description?: string;
    /** page language */
    language?: "it" | "en" | "es" | "de";
}

// component
const SEO = (props: SeoProps) => {
    const { title, description, language } = props;
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        siteUrl
                        keywords
                        image
                        logo
                    }
                }
            }
        `
    );

    // set SEO meta from props and basic gatsby configuration
    const image = site.siteMetadata.image;
    const keywords = site.siteMetadata.keywords;
    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = site.siteMetadata?.title;

    return (
        <Helmet
            htmlAttributes={{ lang: language || "it" }}
            title={title}
            titleTemplate={`%s | ${defaultTitle}`}
            meta={[
                { name: `format-detection`, content: "telephone=no" },
                { name: `description`, content: metaDescription },
                { name: `keywords`, content: keywords },
                { property: `og:title`, content: title },
                { property: `og:description`, content: metaDescription },
                { property: `og:type`, content: `website` },
                { property: `og:image`, content: image },
                { name: `twitter:card`, content: `summary` },
                { name: `twitter:image`, content: image },
                { name: `twitter:title`, content: title },
                { name: `twitter:description`, content: metaDescription },
                { name: `format-detection`, content: "telephone=no" },
            ]}
        ></Helmet>
    );
};

export default SEO;
