import * as React from "react";
import { Typography, Stack, Box } from "@mui/material";

// components
import Link from "../utils/link";
import SocialLinks from "../utils/social-links";

// markup
const Address = () => {
    return (
        <Stack spacing={1}>
            <Typography variant="h4" component="p">
                <strong>
                    Farmacia Settimo Miglio
                    <br />
                    Dott.ssa Annamaria Caobelli
                </strong>
            </Typography>

            <Typography>Via A. Bertoldi 84 - 37026 Settimo di Pescantina (Vr)</Typography>

            <Link type="external" href="tel:+390457150797" title="Chiama">
                Telefono: 045 7150797
            </Link>

            <Link type="external" href="https://wa.me/+393272317444" title="Scrivi su WhatsApp">
                WhatsApp: 327 2317444
            </Link>

            <SocialLinks
                facebook="https://www.facebook.com/farmaciasettimomiglio/"
                instagram="https://www.instagram.com/farmacia_settimo_miglio/"
                whatsapp="https://wa.me/+393272317444"
            />

            <Box pt={3}>
                <Typography>
                    <strong>Dal Lunedì al Venerdì:</strong>
                </Typography>
                <Typography>08:30 - 13:00 | 15:30 - 19:30</Typography>
                <Box mt={2} />
                <Typography>
                    <strong>Sabato:</strong>
                </Typography>
                <Typography>08:30 - 13:00 | 15.30 - 19.00</Typography>
            </Box>
        </Stack>
    );
};

export default Address;
