import * as React from "react";
import { Stack, IconButton, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

// icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedinIcon from "@mui/icons-material/Linkedin";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import YoutubeIcon from "@mui/icons-material/Youtube";

// utils
import Link from "./link";

/** social links */
interface SocialLinksProps {
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    youtube?: string;
    whatsapp?: string;
    phone?: string;
    email?: string;
}

const SocialLinks = (props: SocialLinksProps) => {
    const { facebook, instagram, linkedin, youtube, whatsapp, phone, email } = props;

    // render icon button
    const renderIcon = (url: string, title: string, icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>) => {
        return (
            <Link type="external" href={url} title={title}>
                <IconButton title={title} color="inherit" edge="start">
                    {React.createElement(icon)}
                </IconButton>
            </Link>
        );
    };

    return (
        <Stack spacing={1} direction="row">
            {facebook && renderIcon(facebook, "Facebook", FacebookIcon)}
            {instagram && renderIcon(instagram, "Instagram", InstagramIcon)}
            {linkedin && renderIcon(linkedin, "Linkedin", LinkedinIcon)}
            {youtube && renderIcon(youtube, "Youtube", YoutubeIcon)}
            {whatsapp && renderIcon(whatsapp, "Scrivi su WhatsApp", WhatsAppIcon)}
            {phone && renderIcon(phone, "Chiama", PhoneIcon)}
            {email && renderIcon(email, "Invia Email", EmailIcon)}
        </Stack>
    );
};

export default SocialLinks;
