import * as React from "react";
import {
    Stack,
    Box,
    IconButton,
    Toolbar,
    AppBar,
    useScrollTrigger,
    Drawer,
    List,
    ListItemButton,
    ListItemText,
    Popper,
    Paper,
} from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

// utils
import Link from "./link";
import Section from "./section";
import theme from "./theme";

interface MenuItem {
    /** label menu item */
    label: string;
    /** url menu item */
    url: string;
    /** subitems */
    items?: MenuItem[];
}

interface NavigationProps {
    /** menu collapse */
    collapse?: "sm" | "md" | "lg" | "xl";
    /** menu items */
    navigation: MenuItem[];
    /** section background */
    background?: string;
}

const Navigation = (props: NavigationProps) => {
    const { collapse, navigation, background } = props;
    const collpaseMeu = collapse || "md";
    const textColor = "#fff";

    // menu desktop dropdown
    const [anchorElDropdown, setAnchorElDropdown] = React.useState(null);
    const openDropdown = Boolean(anchorElDropdown);
    const handleClickDropdown = (event) => setAnchorElDropdown(event.currentTarget);
    const handleCloseDropdown = () => setAnchorElDropdown(null);

    // menu desktop dropdown
    const [anchorElMobile, setAnchorElMobile] = React.useState(null);
    const openMobile = Boolean(anchorElMobile);
    const handleClickMobile = (event) => setAnchorElMobile(event.currentTarget);
    const handleCloseMobile = () => setAnchorElMobile(null);

    /** scroll trigger, for enable header background */
    const trigger = useScrollTrigger({
        target: typeof window !== `undefined` ? window : undefined,
        disableHysteresis: true,
        threshold: 80,
    });

    const renderSubmenu = (items: any[]) => {
        return (
            <Popper disablePortal anchorEl={anchorElDropdown} open={openDropdown} onClick={handleCloseDropdown}>
                <Box pt={2}>
                    <Paper variant="elevation" elevation={0}>
                        <Box minWidth={160} display="flex" flexDirection="column" onMouseLeave={handleCloseDropdown}>
                            <List>
                                {items.map((item, index) => {
                                    return (
                                        <Box key={index}>
                                            <Link
                                                type="internal"
                                                href={item.url}
                                                activeStyle={{ borderBottom: `2px solid` }}
                                                underline="none"
                                                partiallyActive={item.url !== "/"}
                                                title={item.label}
                                            >
                                                <ListItemButton>
                                                    <strong>{item.label}</strong>
                                                </ListItemButton>
                                            </Link>
                                        </Box>
                                    );
                                })}
                            </List>
                        </Box>
                    </Paper>
                </Box>
            </Popper>
        );
    };

    const renderMenuItems = (items: any[]) => {
        return items.map((item, index) => {
            return (
                <Box key={index} onMouseLeave={item.items && handleCloseDropdown} style={{ color: textColor }}>
                    <Box onMouseOver={item.items && handleClickDropdown}>
                        <Link
                            type="internal"
                            href={item.url}
                            activeStyle={{ borderBottom: `2px solid` }}
                            underline="none"
                            partiallyActive={item.url !== "/"}
                            title={item.label}
                        >
                            <strong>{item.label}</strong>
                        </Link>
                    </Box>
                    {item.items && renderSubmenu(item.items)}
                </Box>
            );
        });
    };

    return (
        <AppBar
            position="fixed"
            style={{ border: 0, background: trigger ? background : "transparent" }}
            elevation={trigger ? 3 : 0}
            color={"transparent"}
        >
            <Section color="#fff" sx={{ py: 0 }}>
                <Toolbar disableGutters style={{ display: "block" }}>
                    <Stack spacing={1} direction="row" sx={{ py: [1, 1, 2] }} alignItems="center">
                        <Link type="internal" href="/" underline="none" title="home">
                            <StaticImage src="../images/logo.svg" alt="Logo" height={80} quality={100} />
                        </Link>
                        <Stack
                            sx={{ display: { xs: "none", [collpaseMeu]: "flex" } }}
                            spacing={5}
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            flexGrow={1}
                        >
                            {renderMenuItems(navigation)}
                        </Stack>
                        <Box
                            flexGrow={1}
                            justifyContent="flex-end"
                            sx={{ display: { xs: "flex", [collpaseMeu]: "none" } }}
                        >
                            <IconButton onClick={handleClickMobile} title="Menu" color="inherit" edge="end">
                                <MenuIcon />
                            </IconButton>
                        </Box>

                        <Drawer open={openMobile} anchor="right" onClose={handleCloseMobile}>
                            <Toolbar>
                                <Box justifyContent="flex-end" width={1} display="flex">
                                    <IconButton edge="end" onClick={handleCloseMobile} title="Close menu">
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <Box width={240}>
                                <List>
                                    {navigation.map((item, index) => (
                                        <Link
                                            key={index}
                                            type="internal"
                                            href={item.url}
                                            underline="none"
                                            title={item.label}
                                        >
                                            <ListItemButton>
                                                <ListItemText primary={item.label} />
                                            </ListItemButton>
                                        </Link>
                                    ))}
                                </List>
                            </Box>
                        </Drawer>
                    </Stack>
                </Toolbar>
            </Section>
        </AppBar>
    );
};

export default Navigation;
