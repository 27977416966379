import * as React from "react";
import { ThemeProvider, Grid, Stack, Typography, CssBaseline } from "@mui/material";

// utils
import { navigation } from "../config";
import Header from "./header";
import theme from "./theme";
import SEO from "./seo";
import Section from "./section";
import Link from "./link";

// component
import Address from "../components/address";

interface PageProps {
    /** page title */
    title: string;
    /** page subtitle */
    description: string;

    /** page content */
    children: JSX.Element | JSX.Element[];
}

const Page = (props: PageProps) => {
    const { title, description, children } = props;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <SEO title={title} description={description} />

            <Header background={theme.palette.primary.main} navigation={navigation} />

            {children}

            <Section color="#fff" background={theme.palette.primary.main}>
                <Grid container>
                    <Grid item xs={12}>
                        <Address />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                            <Typography variant="caption">
                                © {new Date().getFullYear()} - Farmacia Settimo Miglio
                            </Typography>
                            <Typography variant="caption">
                                <Link
                                    title="Credits - Federico Solfa"
                                    type="external"
                                    href="https://www.federicosolfa.it"
                                >
                                    credits
                                </Link>
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Section>
        </ThemeProvider>
    );
};

export default Page;
