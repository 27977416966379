import * as React from "react";
import { Grid, Stack, Typography, Box } from "@mui/material";

// utils
import Section, { SectionProps } from "./section";

interface HeroProps extends SectionProps {
    /** page title */
    title: string | JSX.Element;
    /** page subtitle */
    description?: string | JSX.Element;
    /** hero image */
    image?: JSX.Element;
}

const Hero = (props: HeroProps) => {
    const { title, description, image } = props;

    return (
        <Section {...props}>
            <Box pt={12}>
                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                            <Typography variant="h2" component="h1">
                                {title}
                            </Typography>

                            {description && <Typography component="div">{description}</Typography>}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {image}
                    </Grid>
                </Grid>
            </Box>
        </Section>
    );
};

export default Hero;
