import * as React from "react";
import { Box, Container, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

// utils
import theme from "../utils/theme";

export interface SectionProps {
    /** children element */
    children?: JSX.Element;
    /** section background */
    background?: string;
    /** section background image */
    backgroundImage?: JSX.Element;
    /** section text background */
    color?: string;
    /** section sx styles */
    sx?: SystemStyleObject<Theme>;
}

const Section = (props: SectionProps) => {
    const { background, backgroundImage, color, sx, children } = props;
    const textColor = color || theme.palette.getContrastText(background || theme.palette.background.paper);
    const boxSpacing = sx || { py: [3, 4, 5, 6] };

    return (
        <Box style={{ background, color: textColor }} sx={{ ...boxSpacing }} position="relative" display="grid">
            <Box position="relative" zIndex={1}>
                <Container>{children}</Container>
            </Box>

            {backgroundImage && (
                <Box position="absolute" left={0} right={0} top={0} bottom={0} overflow="hidden" zIndex={0}>
                    {React.cloneElement(backgroundImage, {
                        quality: 80,
                        style: { height: "100%", width: "100%" },
                        layout: "fullWidth",
                    })}
                </Box>
            )}
        </Box>
    );
};

export default Section;
