import * as React from "react";
import { Link as MuiLink } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";

// utils
import { useFirebase } from "./useFirebase";

interface LinkProps {
    /** href (eg. /home, https://www.domain.ext) */
    href: string;
    /** link title, used for non text link and for analytics event */
    title: string;
    /** link destination type */
    type: "internal" | "external";
    /** link underline */
    underline?: "none" | "hover" | "always";
    /** current active or children (eg. for highlight parent menu link) */
    partiallyActive?: boolean;
    /** children element */
    children: string | JSX.Element;
    /** style when it's current link */
    activeStyle?: object;
}

const Link = (props: LinkProps) => {
    const { href, title, type, underline, children, partiallyActive, activeStyle } = props;
    const isInternal = type === "internal";

    /** internal link without page reload */
    if (isInternal) {
        return (
            <MuiLink
                component={GatsbyLink}
                color="inherit"
                underline={underline}
                to={href}
                title={title}
                activeStyle={activeStyle}
                partiallyActive={partiallyActive}
            >
                {children}
            </MuiLink>
        );
    }

    /** redirect user to external site */
    return (
        <MuiLink color="inherit" underline={underline} href={href} title={title} rel="noreferrer" target="_blank">
            {children}
        </MuiLink>
    );
};

export default Link;
